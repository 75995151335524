import React from "react"
import styled from "styled-components";
import ContactForm from "../../components/landing/ContactForm";
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import background from "../../images/source/fondo_ventsup.png"
import logo from "../../images/source/logo_bimov_landing.png"
import background_small from "../../images/source/fondo_ventsup_small.png"
import imgBimov from "../../images/source/imagen_bimov.png"

const Bimov = ({ location }) => (
  <Layout location={location}>
    <SEO title="Bimov" />
    <Styled>
      <div className="info">
        <h3>Hazlo inteligente. Hazlo con nosotros.</h3>
        <img src={logo} alt="logo" height='85px' width='300px' />
        <p>Business Intelligence Solution.</p>
        <img width="350" height="196" src={imgBimov}  alt="img" />
        <div className="background">
          <div className="text">
            <ul>
              <li>Solución en la nube y a medida.</li>
              <li>Efectividad y unificación de los datos, de todas las fuentes.</li>
              <li>Descubrimiento y definición de KPI’s  y métricas estratégicas.</li>
              <li>Resultados a corto plazo.</li>
              <li>Insights clave de tu empresa.</li>
              <li>Centrada en la experiencia de tus usuarios.</li>
              <li>Acompañamiento de un equipo técnico multidisciplinario.</li>
              <li>Ahorro económico.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contact">
        <ContactForm  page="Bimov" location={location} 
        title={'Tomá decisiones de negocios más inteligentes, en base a los datos de tu empresa.'} 
        paddingTitle='0px' 
        title2={'Contáctanos para conocer nuestros casos de BI que creamos para empresas como la tuya.'} />
      </div>
    </Styled>
  </Layout>
)

const Styled = styled.div`
height: 100%;
min-height: 850px;
padding-top: 50px;
display:flex;
background-image: url("${background}");
flex-wrap: wrap;
flex-direction: row;
justify-content: center;
background-size: cover;
background-position-y: -40px;
background-position-x: 0px;
background-repeat-y: no-repeat;


@media (max-width: 1100px) {
  background-position-y: -40px;
  background-position-x: -355px !important;
}

@media (max-width: 1300px) {
  background-position-y:  -40px;
  background-position-x:-355px !important;
}

@media (max-width: 1600px) {
  background-position-y:  -0px;
  background-position-x:-220px;
}

@media (max-width: 780px) {
  flex-direction: column-reverse;
  background: none;
  padding-top: 0px;

}

@media (min-width: 780px) and (max-width: 1050px){
  height: 56%;
}

 .info {

    margin-top: 60px;
    
    
    @media (max-width: 780px) {
      margin-top: 20px;
    }

    img {
      margin-bottom: 0px;
      border-radius: 8px;
      @media (max-width: 780px){
        margin: 0px 0px 10px 0px;
      }
    }

    h3 {
        height: 17px;
        width: 350px;
        color: #007881;
        font-size: 13.92px;
        font-weight: bold;
        letter-spacing: 0.38px;
        line-height: 17px;
        @media (max-width: 780px){
          padding-left: 12px;
        }
    }

    p {
      height: 20px;
      width: 329px;
      color: #000000;
      font-family: "Century Gothic";
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }

    iframe {
      margin-bottom: 15px;

      @media (max-width: 780px) {
        width: 375px;
        height: 220px;
        margin-bottom: 0px !important;      
      }
    }
    
    @media (max-width: 780px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .text {
        margin-top: 15px;
        height: 192px;
        width: 350px;
        color: #FFFFFF;
        font-family: "Century Gothic";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;

        ul {

          list-style: none;
          position: relative;
          left: -20px;

          li {
            width: 350px;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;

            &:before {
              content: "•";
              color: #E3DE1E;
              font-weight: bold;
              display: inline-block;
              width: 1em;
              margin-left: -1em;
            }
          }
          
        }

                
        @media (max-width: 780px) {
          margin: 0 auto;
          padding-top: 20px;
          height: 350px;
        }
    }

    .background {
      @media (max-width: 780px) {
            width: 100%;
            background-image: url("${background_small}");
        }
    }

 }

 .contact {
    margin-top: 70px;
    padding: 40px;
    height: 588px;
    width: 573px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
    margin-left: 110px;
    
    @media (min-width: 780px) and (max-width: 1050px){
      margin: 200px 0px 30px 0px;

    }

    @media (max-width: 900px) {
        margin-left: 0px;
    }

    @media (max-width: 780px) {
      box-shadow: none;
      border-radius: 0;
      margin: 0px 0px 20px 0px;
      padding: 20px;
      width: 100%;
    }
 }

.title{
  font-size: 22px;
}

.title2{
  color: #000000;
  font-family: Questrial;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

`


export default Bimov;